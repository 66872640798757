<h1>Primeval Arcana Interactive Character Sheet</h1>
<div>
  <div>
    <div>
      <button class="new-character" mat-raised-button color="primary" (click)="newCharacter()">Create New Character
        <mat-icon>person</mat-icon>
      </button>
    <button mat-raised-button color="primary" (click)="uploader.click()">Import Existing Character
      <mat-icon>arrow_upwards</mat-icon>
    </button>
    <input type="file" id="upload" #uploader (change)="importGame($event)"/>
  </div>

</div>
